import React, { useState } from "react";
import "./emailSender.scss";
import { Form, Input, Select, Button, Row, Col } from "antd";
import { MailOutlined } from "@ant-design/icons";
import Notify from "../common/Notify/notify";
import DashboardHeader from "../dashboard/DashboardHeader";

const EmailSender = (props) => {
  const { Option } = Select;
  const { callEmailSender, sourceData } = props;

  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFinish = async (values) => {
    setIsSubmitting(true);
    try {
      let payloadData = {
        ...values,
      };
      const data = {
        symbol: "$",
        trial_amount: "1.25",
        amount: "39",
        payment_method_key: "PAYMENT_METHOD_VISA",
      };
      if (
        values.template === "order_confirmation" ||
        values.template === "billing"
      ) {
        payloadData.data = data;
      }
      const result = await callEmailSender(payloadData);
      if (result.type === "emailSender/fulfilled") {
        Notify("success", "Email sent successfully", "");
        form.resetFields();
      } else {
        Notify("error", result.error.message, "");
      }
    } catch (error) {
      Notify("error", "Email send failed", "");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="dashboard-main-section">
      <div className="container">
        <DashboardHeader />
        <div className="form-container">
          <Form
            form={form}
            name="myForm"
            onFinish={onFinish}
            layout="vertical"
            className="custom-form"
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined />}
                    placeholder="Enter your email"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="website"
                  label="Website"
                  rules={[
                    { required: true, message: "Please select a website!" },
                  ]}
                >
                  <Select
                    placeholder="Select a website"
                    className="custom-select"
                  >
                    {sourceData?.sites?.map((sites) => (
                      <Option key={sites.value} value={sites.value}>
                        {sites.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="language"
                  label="Language"
                  rules={[
                    { required: true, message: "Please select a language!" },
                  ]}
                >
                  <Select
                    placeholder="Select a language"
                    className="custom-select"
                  >
                    {sourceData?.languages?.map((lang) => (
                      <Option key={lang.value} value={lang.value}>
                        {lang.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="template"
                  label="Email Template"
                  rules={[
                    {
                      required: true,
                      message: "Please select an email template!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select an email template"
                    className="custom-select"
                  >
                    {sourceData?.templates?.map((template) => (
                      <Option key={template.value} value={template.value}>
                        {template.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item className="setting-btn">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EmailSender;
