import React, { useEffect, useState } from "react";
import EmailSender from "./EmailSender";
import { connect } from "react-redux";
import { emailSender } from "../../redux/slice/emailSenderSlice";
import { getEmailConfigData } from "../../redux/slice/getEmailConfigDataSlice";
import Notify from "../common/Notify/notify";

const EmailSenderPage = (props) => {
  const { callEmailSender, getAdminConfig } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [sourceData, setSourceData] = useState([
    {
      languages: [],
      sites: [],
      templates: [],
    },
  ]);
  console.log(sourceData)
  useEffect(() => {
    getAdminConfigData();
  // eslint-disable-next-line
  }, []);

  const getAdminConfigData = async () => {
    setIsLoading(true);
    try {
      const result = await getAdminConfig();
      if (result.type === "getEmailConfigData/fulfilled") {
        const {
          languages = [],
          sites = [],
          templates = [],
        } = result?.payload?.data || {};
        setSourceData({
          languages: languages.map((lang) => ({ label: lang, value: lang })),
          sites: sites.map(({ name }) => ({
            label: name,
            value: name,
          })),
          templates: templates.map((template) => ({
            label: template?.label,
            value: template?.value,
          })),
        });
      } else {
        Notify("error", result.error?.message || "An error occurred.");
      }
    } catch (error) {
      Notify("error", error.message || "An unexpected error occurred.", "");
    } finally {
      setIsLoading(false);
    }
  };
  return <EmailSender callEmailSender={callEmailSender} sourceData={sourceData} />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    callEmailSender: (data) => dispatch(emailSender(data)),
    getAdminConfig: () => dispatch(getEmailConfigData()),
  };
};

export default connect(null, mapDispatchToProps)(EmailSenderPage);
