import visaIconHolder from "../../../assets/checkout/visaIconHolder.png";
import mastercardIconHolder from "../../../assets/checkout/mastercardIconHolder.png";
import maestroIconHolder from "../../../assets/checkout/maestroIconHolder.png";
import jcbIconHolder from "../../../assets/checkout/jcbIconHolder.png";
import discoverIconHolder from "../../../assets/checkout/discoverIconHolder.png";
import unionpayIconHolder from "../../../assets/checkout/unionpayIconHolder.png";
import americanExpressIconHolder from "../../../assets/checkout/americanExpressIconHolder.png";
import dinersClubIconHolder from "../../../assets/checkout/dinersClubIconHolder.png";
import cartesBancairesIconHolder from "../../../assets/checkout/cartesBancairesIconHolder.png";
import defaultIconHolder from "../../../assets/checkout/defaultIconHolder.png";
import gPayIcon from "../../../assets/checkout/gPayIcon.svg";
import applePayIconHolder from "../../../assets/checkout/applePayIconHolder.png";
import { Cookies } from "react-cookie";
import { isSupportedLang } from "../helpers";
import { t } from "i18next";
import { renderRTLClass } from "../../../utils/commonUtils";

const DummyLayout = ({ isGooglePayAvailable, isApplePayAvailable, cardType }) => {
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const isSupport = isSupportedLang(lang);
  const getCardBrandImage = (cardType) => {
    switch (cardType) {
      case "VISA":
        return visaIconHolder;
      case "MASTERCARD":
        return mastercardIconHolder;
      case "MAESTRO":
        return maestroIconHolder;
      case "JCB":
        return jcbIconHolder;
      case "DISCOVER":
        return discoverIconHolder;
      case "UNIONPAY":
        return unionpayIconHolder;
      case "AMERICAN EXPRESS":
        return americanExpressIconHolder;
      case "DINERS CLUB SHORT":
        return dinersClubIconHolder;
      case "CARTES BANCAIRES":
        return cartesBancairesIconHolder;
      default:
        return defaultIconHolder;
    }
  };

  const cardBrandImage = getCardBrandImage(cardType);

  return (
    <div className="dummy-layout">
      {isGooglePayAvailable && isApplePayAvailable ? (
        <div className="payment__mrg payment_option_btn">
          <div className="defult-payment-button dummy_apple-pay-button">
            Pay with <img src={gPayIcon} alt="Google Pay" />
          </div>
          <div className="defult-payment-button dummy_apple-pay-button">
            check out with <img src={applePayIconHolder} alt="Apple Pay" />
          </div>
        </div>
      ) : isGooglePayAvailable ? (
          <div className="payment__mrg single_payment_btn">
            <div className="defult-payment-button dummy_apple-pay-button">
              Pay with <img src={gPayIcon} alt="Google Pay" />
            </div>
          </div>
      ) : isApplePayAvailable ? (
            <div className="payment__mrg single_payment_btn">
              <div className="defult-payment-button dummy_apple-pay-button">
                check out with <img src={applePayIconHolder} alt="Apple Pay" />
              </div>
            </div>
      ) : null}
      {/* Card Number Input */}
      <div className="input-group">
        <label className="input-label">
          {isSupport
            ? t("TS_CARD_NUMBER_TITLE")
            : "Credit or Debit Card Number"}
        </label>
        <input
          type="text"
          placeholder="XXXX XXXX XXXX XXXX"
          className="input-field"
        />
        {cardBrandImage && (
          <img
            src={cardBrandImage}
            alt={`${cardType} Logo`}
            className="card-brand"
            style={{ height: 50, width: 50 }}
          />
        )}
      </div>

      {/* Expiry Date and CVV Section */}
      <div className="expiry-cvv-section">
        {/* Expiry Date */}
        <div className={`${renderRTLClass()} expiry-date`}>
          <label className="input-label">
            {isSupport ? t("TS_CARD_EXPIRY_DATE_TITLE") : "Expiry Date"}
          </label>
          <input
            type="text"
            placeholder={isSupport ? t("TS_CARD_MM_YY_TITLE") : "MM/YY"}
            className="input-field"
          />
        </div>
        {/* CVV */}
        <div  className={`${renderRTLClass()} cvv`}>
          <label className="input-label">
            {isSupport ? t("TS_CARD_CVC_CVV_TITLE") : "CVV/CVC"}
          </label>
          <input
            type="text"
            placeholder={isSupport ? t("TS_CARD_CVV_TITLE") : "CVV"}
            className="input-field"
          />
        </div>
      </div>
    </div>
  );
};

export default DummyLayout;
